import * as actionTypes from "../types";
import {
  getWeb3 as _getWeb3,
  getAccounts as _getAccounts,
  getEtherBalance as _getEtherBalance,
  getNFTContractInstance as _getNFTContractInstance,
} from "../../utils/getWeb3";

//TODO
export const connectWallet = () => async (dispatch) => {
  try {
    const web3 = await _getWeb3();
    const accounts = await _getAccounts();
    const etherBalance = await _getEtherBalance();
    const nftContractInstance = await _getNFTContractInstance();
    dispatch({
      type: actionTypes.CONNECT_WALLET,
      payload: {
        web3,
        accounts,
        etherBalance,
        nftContractInstance,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: actionTypes.SET_ERRORS,
      payload: "didn't connect to wallet",
    });
  }
};

export const disconnectWallet = () => async (dispatch) => {
  dispatch({
    type: actionTypes.DISCONNECT_WALLET,
  });
};

export const getWeb3 = () => async (dispatch) => {
  try {
    const web3 = await _getWeb3();
    dispatch({ type: actionTypes.GET_WEB3, payload: web3 });
  } catch (err) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: "web3 error" });
  }
};

export const getAccounts = () => async (dispatch) => {
  try {
    const accounts = await _getAccounts();
    dispatch({ type: actionTypes.GET_ACCOUNTS, payload: accounts });
  } catch (err) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: "account error" });
  }
};

export const getEtherBalance = () => async (dispatch) => {
  try {
    let etherBalance = await _getEtherBalance();
    dispatch({ type: actionTypes.GET_ETHER_BALANCE, payload: etherBalance });
  } catch (err) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: "balance error" });
  }
};
