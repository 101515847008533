import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "rgba(242, 235, 238, 1)" },
    primary: {
      light: "rgba(96, 69, 73, 1)",
      main: "rgba(54, 35, 37, 1)",
      dark: "rgba(57, 38, 38, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(246, 161, 36, 1)",
      main: "rgba(235, 141, 0, 1)",
      dark: "rgba(191, 117, 5, 1)",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(54, 35, 37, 1)",
      secondary: "rgba(173, 181, 189, 1)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  /** components */
  typography: {
    fontFamily: [
      "IncompleetaRegular",
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default theme;
