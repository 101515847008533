import * as actionTypes from "../types";

const initialState = {
  isAuth: false,
  web3: null,
  accounts: null,
  etherBalance: 0,
  nftContractInstance: null,
  errors: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CONNECT_WALLET:
      return {
        ...state,
        isAuth: true,
        web3: action.payload.web3,
        accounts: action.payload.accounts,
        etherBalance: action.payload.etherBalance,
        nftContractInstance: action.payload.nftContractInstance,
        errors: [],
      };
    case actionTypes.DISCONNECT_WALLET:
      return initialState;
    case actionTypes.GET_WEB3:
      return {
        ...state,
        web3: action.payload,
        errors: [],
      };
    case actionTypes.GET_ETHER_BALANCE:
      return {
        ...state,
        etherBalance: action.payload,
        errors: [],
      };
    case actionTypes.GET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        errors: [],
      };
    case actionTypes.SET_ERRORS:
      return {
        ...initialState,
        errors: action.payload,
      };

    default:
      return state;
  }
}
