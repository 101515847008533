import React, { useEffect, useState } from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Grid,
  Button,
  Typography,
  TextField,
  IconButton,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import { useDispatch, useSelector } from "react-redux";
import {
  connectWallet,
  disconnectWallet,
} from "../redux/actions/walletActions";

import { ReactComponent as TwitterLogo } from "../assets/svg/twitter.svg";
import { ReactComponent as OpenseaLogo } from "../assets/svg/opensea.svg";
import { ReactComponent as DiscordLogo } from "../assets/svg/discord.svg";
import origami1 from "../assets/img/1.jpg";
import origami2 from "../assets/img/2.jpg";

import dividerImage from "../assets/img/divider.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },
  toolbarGrid: {
    maxWidth: 1200,
  },
  socialIcon: {
    width: 34,
    color: theme.palette.text.secondary,
    // textColor: "white",
  },
  walletButton: {
    margin: theme.spacing(1),
    height: 40,
    fontWeight: 600,
  },
  headerTextContainer: {
    position: "relative",
  },
  textAbsolute: {
    position: "absolute",
    right: -280,
    [theme.breakpoints.down("xs")]: {
      padding: 20,
      position: "relative",
      right: 0,
    },
  },
  headerTextStyle: {
    fontSize: 92,
    lineHeight: "100%",
    letterSpacing: "-4px",
    // position: "absolute",
    [theme.breakpoints.down("xs")]: {
      fontSize: 72,
      letterSpacing: "-4px",
      // position: "relative",
    },
  },
  textContainer: {
    maxWidth: 400,
  },

  textStyle: {
    textTransform: "none",
    fontFamily: "MuseoModerno",
    marginTop: 40,
    [theme.breakpoints.down("xs")]: {
      margin: 10,
    },
  },
  firstImage: {
    maxWidth: 720,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 370,
    },
  },
  mintContainer: {
    marginTop: 40,
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      justifyContent: "center",
    },
  },
  mintButtonContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  textField: {
    width: 100,
    backgroundColor: theme.palette.common.white,
    // size: "medium"
  },
  textFieldPrice: {
    width: 100,
    backgroundColor: theme.palette.common.white,
    // size: "medium"
  },
  mintButton: {
    width: 200,
    height: 60,
    borderRadius: 0,
    fontSize: 32,
    fontFamily: "MuseoModerno",
    textTransform: "none",
  },
  priceDescription: {
    textTransform: "none",
    fontFamily: "MuseoModerno",
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  divider: {
    maxWidth: 70,
    margin: 20,
  },
  secondImage: {
    width: "80%",
  },
  rarityText: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  footer: {
    marginTop: 30,
    backgroundColor: theme.palette.primary.main,
  },
  footerContent: {
    padding: 30,
  },
}));

export default function LandingPage() {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);

  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar = (msg) => {
    enqueueSnackbar(msg);
  };

  const [state, setstate] = useState({
    mintPrice: 0.08,
    weiPrice: 0,
    quantity: 1,
  });

  useEffect(() => {
    const fetchWallet = () => {
      dispatch(connectWallet());
    };
    if (wallet.isAuth) {
      window.ethereum.on("accountsChanged", () => {
        fetchWallet();
      });
      window.ethereum.on("disconnect", () => {
        disconnectWallet();
      });
    } else {
      fetchWallet();
    }
  }, [dispatch, wallet.isAuth]);

  useEffect(() => {
    const fetchMintPrice = async () => {
      if (wallet.isAuth) {
        const { nftContractInstance, accounts } = wallet;
        // console.log(nftContractInstance);
        let mintPrice = await nftContractInstance.methods
          .getCurrentPrice()
          .call({ from: accounts[0] });
        // console.log(mintPrice);

        setstate((prevItems) => ({
          ...prevItems,
          weiPrice: mintPrice,
          mintPrice: weiToEther(mintPrice),
        }));
      }
    };
    fetchMintPrice();
    // eslint-disable-next-line
  }, [wallet]);

  const connWallet = () => {
    dispatch(connectWallet());
  };

  const weiToEther = (wei) => {
    if (wallet.isAuth) {
      const { web3 } = wallet;
      let toWei = web3.utils.fromWei(wei, "ether");
      // console.log(toWei);
      // console.log(parseFloat(Number(toWei).toFixed(4)));
      return parseFloat(Number(toWei).toFixed(2));
    }
  };

  const mintNFT = async () => {
    if (wallet.isAuth) {
      const { nftContractInstance, accounts } = wallet;

      let mintTotalPrice = state.weiPrice * state.quantity;

      await nftContractInstance.methods
        .mint(state.quantity)
        .send({ from: accounts[0], value: mintTotalPrice.toString() });
      // console.log(mintResult);
      showSnackbar("You have successfully minted!");
    } else {
      showSnackbar("Please connect your wallet.");
    }
  };

  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (
      e.target.value === "" ||
      (e.target.value <= 10 && re.test(e.target.value))
    ) {
      let mintTotalPrice = state.weiPrice * e.target.value;
      mintTotalPrice = weiToEther(mintTotalPrice.toString());
      // console.log(mintTotalPrice.toString());

      setstate((prevItems) => ({
        ...prevItems,
        quantity: e.target.value,
        mintPrice: mintTotalPrice,
      }));
    } else {
      setstate((prevItems) => ({
        ...prevItems,
        quantity: 1,
      }));
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            // spacing={2}
            className={classes.toolbarGrid}
          >
            <Grid item>
              <IconButton aria-label="Twitter">
                <TwitterLogo className={classes.socialIcon} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="Opensea">
                <OpenseaLogo className={classes.socialIcon} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="Discord">
                <DiscordLogo className={classes.socialIcon} />
              </IconButton>
            </Grid>
            <Grid item>
              {wallet.isAuth ? (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.walletButton}
                  onClick={connWallet}
                >
                  {wallet.accounts[0].substring(0, 4)}...
                  {wallet.accounts[0].substring(
                    wallet.accounts[0].length - 4,
                    wallet.accounts[0].length
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.walletButton}
                  onClick={connWallet}
                >
                  Connect to wallet
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container justifyContent="center">
        <Grid item sm container>
          <Grid item container className={classes.headerTextContainer}>
            <Grid item className={classes.textAbsolute}>
              <Typography
                variant="h1"
                color="primary"
                className={classes.headerTextStyle}
              >
                THE <br />
                LOST <br />
                ORIGAMI
              </Typography>
              <div className={classes.textContainer}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  className={classes.textStyle}
                >
                  Join the quest to collect the rarest of them well. Throughout civilizations origami has been an ancient art. TLO are ERC-721 tokens stored on the Ethereum Blockchain. Each unique and defer in rarity.
                </Typography>
              </div>

              <Grid
                container
                wrap="nowrap"
                alignItems="center"
                className={classes.mintContainer}
              >
                <Grid item>
                  <TextField
                    id="quantity"
                    variant="outlined"
                    inputProps={{
                      style: { fontSize: 34, textAlign: "center", padding: 5 },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: 0,
                        paddingBottom: 15,
                        height: 60,
                      },
                    }}
                    onChange={handleChange}
                    value={state.quantity}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="price"
                    inputProps={{
                      style: { fontSize: 18, textAlign: "center", padding: 5 },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: 0,
                        paddingBottom: 15,
                        color: "#362325 ",
                        height: 60,
                      },
                    }}
                    disabled
                    value={state.mintPrice + " ETH"}
                    variant="outlined"
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.mintButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => mintNFT()}
                  className={classes.mintButton}
                >
                  mint
                </Button>
              </Grid>
              <Typography
                variant="subtitle1"
                color="primary"
                className={classes.priceDescription}
              >
                0.08 ETH per piece
                <br />
                Edition of 10,000
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={8}>
          <img src={origami1} alt="origami" className={classes.firstImage} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <img src={dividerImage} alt="divider" className={classes.divider} />
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="h3" color="primary">
            BackStory
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" spacing={2}>
          <Grid item sm={5}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.textStyle}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>
          </Grid>
          <Grid item sm={5}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.textStyle}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <img src={dividerImage} alt="divider" className={classes.divider} />
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="h3" color="primary">
            BackStory
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" spacing={2}>
          <Grid item sm={10}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.textStyle}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Gravida in fermentum et sollicitudin. Urna cursus eget nunc
              scelerisque viverra mauris in. Nec sagittis aliquam malesuada
              bibendum arcu. In pellentesque massa placerat duis ultricies lacus
              sed turpis. Eget aliquet nibh praesent tristique magna sit.
              Malesuada pellentesque elit eget gravida. Etiam dignissim diam
              quis enim lobortis scelerisque. Netus et malesuada fames ac turpis
              egestas sed tempus. Feugiat scelerisque varius morbi enim nunc. Et
              molestie ac feugiat sed. Sit amet aliquam id diam maecenas
              ultricies mi eget. Aliquam vestibulum morbi blandit cursus.
              Fringilla phasellus faucibus scelerisque eleifend donec pretium
              vulputate sapien nec. Cursus in hac habitasse platea dictumst
              quisque. Risus commodo viverra maecenas accumsan lacus vel
              facilisis. Vitae aliquet nec ullamcorper sit amet risus nullam.
              Arcu odio ut sem nulla pharetra diam. Sed vulputate odio ut enim.
              Nunc sed velit dignissim sodales ut eu. Ullamcorper morbi
              tincidunt ornare massa eget egestas purus. Pharetra vel turpis
              nunc eget lorem. Eu feugiat pretium nibh ipsum consequat nisl vel.
              Adipiscing elit duis tristique sollicitudin nibh. Ac placerat
              vestibulum lectus mauris. Arcu bibendum at varius vel pharetra vel
              turpis nunc. Semper auctor neque vitae tempus quam. Sit amet
              dictum sit amet justo donec enim diam. Sed ullamcorper morbi
              tincidunt ornare massa. Odio tempor orci dapibus ultrices in
              iaculis nunc sed augue. Quisque egestas diam in arcu cursus
              euismod quis. Etiam non quam lacus suspendisse.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <img src={dividerImage} alt="divider" className={classes.divider} />
      </Grid>
      <Grid container justifyContent="center">
        <Grid item container justifyContent="center" spacing={2}>
          <Grid item sm={5} container justifyContent="center">
            <img
              src={origami2}
              alt="origami2"
              className={classes.secondImage}
            />
          </Grid>
          <Grid item sm={5}>
            <Typography
              variant="h3"
              color="primary"
              className={classes.rarityText}
            >
              Rarity
            </Typography>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.textStyle}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <footer className={classes.footer}>
        <Grid
          container
          className={classes.footerContent}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={5}>
            <Typography color="textSecondary" className={classes.copyright}>
              2021 The Lost Origami NFT © All rights Reserve
            </Typography>
          </Grid>
          <Grid
            item
            sm={5}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <IconButton aria-label="Twitter">
                <TwitterLogo className={classes.socialIcon} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="Opensea">
                <OpenseaLogo className={classes.socialIcon} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="Discord">
                <DiscordLogo className={classes.socialIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </div>
  );
}
