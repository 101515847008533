import React from "react";
import { ThemeProvider } from "@material-ui/core";
import theme from "./utils/theme";
import { SnackbarProvider } from "notistack";

/** Redux */
import { Provider } from "react-redux";
import store from "./redux/store";

/**Pages */
import LandingPage from "./pages/LandingPage";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <LandingPage />
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
}
