import Web3 from "web3";

/** contracts */
import NFTContract from "../contracts/nfttest.json";

//TODO optimize dis build
const getWeb3 = async () => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.REACT_APP_NETWORK }],
      });

      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();

      return web3;
    } catch (err) {
      throw err;
    }
  }
};

const getAccounts = async () => {
  try {
    const web3 = await getWeb3();
    // Use web3 to get the user's accounts.
    const accounts = await web3.eth.getAccounts();
    return accounts;
  } catch (err) {
    throw err;
  }
};

const getEtherBalance = async () => {
  const web3 = await getWeb3();
  let accounts = await getAccounts();

  let etherBalance = await web3.eth.getBalance(accounts[0]);

  return etherBalance;
};

const getNFTContractInstance = async () => {
  try {
    const web3 = await getWeb3();

    // Get the contract instance.
    const contractAddress = process.env.REACT_APP_NFT_CONTRACT;
    const nftContractInstance = new web3.eth.Contract(
      NFTContract.abi,
      contractAddress
    );
    console.log(process.env.REACT_APP_NFT_CONTRACT)
    return nftContractInstance;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { getWeb3, getAccounts, getEtherBalance, getNFTContractInstance };
